import "./App.css";
import { useMemo } from "react";

import Home from "./Home";

import * as anchor from "@project-serum/anchor";
import { clusterApiUrl } from "@solana/web3.js";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import {
  getPhantomWallet,
  getSlopeWallet,
  getSolflareWallet,
  getSolletWallet,
  getSolletExtensionWallet,
} from "@solana/wallet-adapter-wallets";

import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";

import { WalletDialogProvider } from "@solana/wallet-adapter-material-ui";
import { createTheme, ThemeProvider } from "@material-ui/core";

const treasury = new anchor.web3.PublicKey(
  process.env.REACT_APP_TREASURY_ADDRESS!
);

const config = new anchor.web3.PublicKey(
  process.env.REACT_APP_CANDY_MACHINE_CONFIG!
);

const candyMachineId = new anchor.web3.PublicKey(
  process.env.REACT_APP_CANDY_MACHINE_ID!
);

const network = process.env.REACT_APP_SOLANA_NETWORK as WalletAdapterNetwork;

const rpcHost = process.env.REACT_APP_SOLANA_RPC_HOST!;
const connection = new anchor.web3.Connection(rpcHost);

const startDateSeed = parseInt(process.env.REACT_APP_CANDY_START_DATE!, 10);

const txTimeout = 30000; // milliseconds (confirm this works for your project)

const theme = createTheme({
  palette: {
    type: 'dark',
  },
  overrides: {
    MuiButtonBase: {
      root: {
        justifyContent: 'flex-start',
      },
    },
    MuiButton: {
      root: {
        textTransform: undefined,
        padding: '12px 16px',
      },
      startIcon: {
        marginRight: 8,
      },
      endIcon: {
        marginLeft: 8,
      },
    },
  },
});


const features = [
  {
    name: 'Durable',
    description: 'The leather cover and machined steel disc binding stand up to daily use for years to come.',
  },
  {
    name: 'Refillable',
    description: 'Buy it once and refill as often as you need. Subscribe and save on routine refills.',
  },
  {
    name: 'Thoughtfully designed',
    description:
      'The comfortable disc binding allows you to quickly rearrange pages or combine lined, graph, and blank refills.',
  },
  { name: 'Locally made', description: 'Responsibly and sustainably made real close to wherever you are, somehow.' },
]

const App = () => {
  const endpoint = useMemo(() => clusterApiUrl(network), []);

  const wallets = useMemo(
    () => [
      getPhantomWallet(),
      getSlopeWallet(),
      getSolflareWallet(),
      getSolletWallet({ network }),
      getSolletExtensionWallet({ network })
    ],
    []
  );

  return (
    <div className="bg-white">
      <div className="max-w-2xl mx-auto py-24 px-4 grid items-center grid-cols-1 gap-y-16 gap-x-8 sm:px-6 sm:py-32 lg:max-w-7xl lg:px-8 lg:grid-cols-2">
        <div>

          <div className="bg-white overflow-hidden rounded-lg divide-y divide-gray-200 border-none">
            <div className="px-4 py-5 sm:px-6">
              <h1 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">abstract2◎23</h1>
              <h2 className="text-2xl tracking-tight text-gray-900 sm:text-4xl">Genesis Collection Mint</h2>
            </div>
            <div className="px-4 py-5 sm:p-6">

              <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <ConnectionProvider endpoint={endpoint}>
                  <WalletProvider wallets={wallets} autoConnect={true}>
                    <WalletDialogProvider>
                      <Home
                        candyMachineId={candyMachineId}
                        config={config}
                        connection={connection}
                        startDate={startDateSeed}
                        treasury={treasury}
                        txTimeout={txTimeout}
                      />
                    </WalletDialogProvider>
                  </WalletProvider>
                </ConnectionProvider>
              </div>
            </div>
            <div className="px-4 py-4 sm:px-6 text-xs text-gray-500">
              <div>
                <p>
                  By minting or transacting in an abstract2023 Genesis Collection NFT you agree to the <a href="https://arweave.net/Go5yoPqTSnVJGwT3HjWCCCfldXweuaJWj3e0wAUjbpo/?ext=pdf" className="underline hover:text-blue-800 visited:text-purple-600">abstract2023 Genesis Collection NFT License Agreement (PDF)</a>. 
                   No colors were harmed in the production of the abstract2023 Genesis Collection.
                </p>
                </div>
            </div>
          </div>


        </div>
        <div className="grid grid-cols-2 grid-rows-2 gap-4 sm:gap-6 lg:gap-8">
          <img
            src="preview_image_1.png"
            alt="abstract2023 Genesis Collection Preview Image #1"
            className="bg-gray-100"
          />
          <img
            src="preview_image_8.png"
            alt="abstract2023 Genesis Collection Preview Image #2"
            className="bg-gray-100"
          />
          <img
            src="preview_image_5.png"
            alt="abstract2023 Genesis Collection Preview Image #3"
            className="bg-gray-100"
          />
          <img
            src="preview_image_6.png"
            alt="abstract2023 Genesis Collection Preview Image #4"
            className="bg-gray-100"
          />
        </div>
      </div>
    </div>


  );
};

export default App;
